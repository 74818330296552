//This class could run in a worker if document.createElement("canvas") is replaced by another (offscreen) Canvas instance
export default class MultiViewComposer {
	constructor() {
		this.selectedVideoIndex = 0;
		this.canvas = document.createElement("canvas");
		this.context = this.canvas.getContext("2d", { alpha: false });
		this.drawFrame = this.drawFrame.bind(this);
	}

	switchTo(videoId)
	{
		console.log("switching to: " + videoId);
		this.selectedVideoIndex = videoId;
	}

	resize(width, height) {
		this.canvas.width = width;
		this.canvas.height = height;
	}

	getCanvas() {
		return this.canvas;
	}

	drawFrame(frame, videoId,isLandscape) {
		
		var video = {
			width: frame.displayWidth,
			height: frame.displayHeight
		}
		var imagePosition = {
			x: 0,
			y: 0,
			width: video.width,
			height: video.height,
		}
		if(isLandscape){
			var mainWindowWith = this.canvas.width - ((this.canvas.height / 4) * video.width) / video.height;
			if (videoId == this.selectedVideoIndex) {
				var canvasPosition = {
					x: 0,
					y: 0,
					width: mainWindowWith,
					height: this.canvas.height,
				};
				this.drawImageInRectangle(this.context, frame, imagePosition, canvasPosition);
			}

			var smallCanvasPosition = {
				x: mainWindowWith,
				y: this.canvas.height / 4 * videoId,
				width: this.canvas.width - mainWindowWith,
				height: this.canvas.height / 4,
			};
			this.drawImageInRectangle(this.context, frame, imagePosition, smallCanvasPosition);
		}else{
			var mainWindowHeight = this.canvas.height - ((this.canvas.width / 4) * video.height) / video.width;
			if (videoId == this.selectedVideoIndex) {
				var canvasPosition = {
					x: 0,
					y: 0,
					width: this.canvas.width,
					height: mainWindowHeight,
				};
				this.drawImageInRectangle(this.context, frame, imagePosition, canvasPosition);
			}
			var smallCanvasPosition = {
				x: (this.canvas.width / 4) * videoId,
				y: mainWindowHeight,
				width: this.canvas.width /4,
				height: this.canvas.height - mainWindowHeight,
			  };
			  this.drawImageInRectangle(this.context, frame, imagePosition, smallCanvasPosition);
		}
		
	}

	drawImageInRectangle(context, video, imagePosition, oldCanvasPosition) {
		context.fillStyle = "black";
		var canvasPosition = {
			x: oldCanvasPosition.x,
			y: oldCanvasPosition.y,
			width: oldCanvasPosition.width,
			height: oldCanvasPosition.height,
		};
		context.fillRect(canvasPosition.x, canvasPosition.y, canvasPosition.width, canvasPosition.height);
		//verification du ratio
		if (canvasPosition.width / canvasPosition.height > imagePosition.width / imagePosition.height) {
			canvasPosition.x += (canvasPosition.width - canvasPosition.height * (imagePosition.width / imagePosition.height)) / 2;
			canvasPosition.width = canvasPosition.height * (imagePosition.width / imagePosition.height);
		} else {
			canvasPosition.y += (canvasPosition.height - canvasPosition.width * (imagePosition.height / imagePosition.width)) / 2;
			canvasPosition.height = canvasPosition.width * (imagePosition.height / imagePosition.width);
		}
		context.drawImage(video, canvasPosition.x, canvasPosition.y, canvasPosition.width, canvasPosition.height);
	}
}