//This class cannot run in a worker because it uses document.createElement("video")
import EventTarget from '@ungap/event-target'
export default class VideosSynchronizer extends EventTarget {
  constructor() {
    super();
    this.tracks = [];
    this.allCanPlay = [];
    this.currentTime = 0;
    this.addVideo = this.addVideo.bind(this);
    this.syncTo = this.syncTo.bind(this);
    this.buffering = true;
  }

  async play() {
	if(this.tracks[0].currentTime === this.tracks[0].duration){
		this.syncTo(0);
	}
    await Promise.all(this.allCanPlay);
    console.log("All tracks are ready");
    this.buffering = false;
    this.allBut(-1, (video) => video.play());
    this.dispatchEvent(new Event("playing"));
  }

  pause() {
    this.allBut(-1, (video) => video.pause());
  }

  getMainVideo() {
    return this.tracks[0];
  }
  seekToRatio(ratio){
	if (this.tracks[0]) {
		var duration = this.tracks[0].duration;
		this.seekTo(duration*ratio);
	}
  }
  seekTo(timestamp) {
    this.currentTime = timestamp;
    if (this.tracks[0]) {
      this.tracks[0].currentTime = timestamp;
    }
    this.syncTo(timestamp);
  }
  changeVolume(volume){
	if (this.tracks[0]) {
		this.tracks[0].volume  = volume;
	  }
  }

  addFirstVideoListeners(track) {
	track.addEventListener("timeupdate", () => {
	  this.currentTime = track.currentTime;
	  this.syncTo(track.currentTime);
	  this.dispatchEvent(
		new CustomEvent("timeupdate", {
		  detail: {
			currentTime: this.currentTime,
			totalDuration : track.duration,
		  },
		})
	  );
	});
    track.addEventListener("pause", () => {
      this.dispatchEvent(
        new CustomEvent("pause", {
          detail: true,
        })
      );
    });

    track.addEventListener("play", () => {
      this.dispatchEvent(
        new CustomEvent("play", {
          detail: true,
        })
      );
    });
    track.addEventListener("volumechange", (e) => {
      this.dispatchEvent(
        new CustomEvent("volumechange", {
          detail: track.volume,
        })
      );
    });
  }

  addVideo(path) {
    console.log("adding track for " + path);
    var trackId = JSON.parse(JSON.stringify(this.tracks.length));
    var track = document.createElement("video");
    track.playsInline = true;
    track.src = path;
    track.currentTime = this.currentTime;
    track.load();
    track.preload = "auto";

    this.allCanPlay.push(
      new Promise((resolve) => {
        track.addEventListener("canplay", resolve, { once: true });
      })
    );

    track.addEventListener(
      "loadedmetadata",
      () => {
        track.displayWidth = track.videoWidth;
        track.displayHeight = track.videoHeight;
        if (trackId == 0) {
          this.dispatchEvent(
            new CustomEvent("size", {
              detail: {
                width: track.videoWidth,
                height: track.videoHeight,
              },
            })
          );
        }
      },
      { once: true }
    );

    track.addEventListener("waiting", () => {
      if (!this.buffering) {
        console.log("video #" + trackId + " is buffering, waiting...");
        this.buffering = true;
        this.dispatchEvent(
          new CustomEvent("buffering", {
            detail: {
              videoId: trackId,
            },
          })
        );
        this.allCanPlay.length = 0;
        this.allCanPlay.push(
          new Promise((resolve) => {
            track.addEventListener("playing", resolve, { once: true });
          })
        );
        this.allBut(trackId, (video) => video.pause());
        this.play();
      } else {
        console.log("video #" + trackId + " is also buffering, trying to catch up");
      }
    });

    if (trackId == 0) {
      // First (main) Video
      this.addFirstVideoListeners(track);
    } else {
      track.muted = true;
    }
    this.tracks.push(track);
  }

  flushVideos() {
    this.allBut(-1, (video) => {
      video.pause();
      video.removeAttribute("src");
      video.load();
    });
    this.tracks.length = 0;
  }

  syncTo(timestamp) {
    this.allBut(0, (video) => {
      if (Math.abs(video.currentTime - timestamp) > 1 / 15) {
        video.currentTime = timestamp;
      }
    });
  }

  /**
   * Applies a function on all the videos except the one specified
   * Use -1 (or any value that doesn't correspond to an index) to apply to all the videos without exception
   * @param {number} me id of the video to avoid
   * @param {function(*)} fn function to apply to each video
   */
  allBut(me, fn) {
    this.tracks.forEach((video, id) => {
      if (me != id) {
        fn(video);
      }
    });
  }
}
