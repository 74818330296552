<template>
  <div id="playerContainer" ref="container">
    <canvas id="canvas" ref="canvas"></canvas>
    <div id="overlays">
      <div
        id="mainOverlay"

        v-bind:style="{
          width: mainOverlay.width,
          height: mainOverlay.height,
          left: mainOverlay.x,
          top: mainOverlay.y,
          backgroundImage: backgroundImagePoster ? 'url(' + backgroundImagePoster + ')' : 'none',
          backgroundSize:'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }"
      >
        <div id="mainOverlayClickZone" @click="play"></div>
        <div id="playBar">
          <div id="leftButtons">
            <span id="playButton" @click="play" class="controlButton"
              ><v-mdi
                id="playIcon"
                :name="icons.play"
                class="controlIcon"
              ></v-mdi
            ></span>
            <span
              id="autopilotButton"
              @click="
                () => {
                  this.autopilotChange();
                }
              "
              class="controlButton"
              v-if="showAutopilotButton"
              ><v-mdi
                id="autopilotIcon"
                :name="icons.autopilot"
                class="controlIcon"
              ></v-mdi
            ></span>
            <span id="nextButton" @click="nextVideo" class="controlButton" v-if="showNextButton"
              ><v-mdi
                id="nextIcon"
                name="mdi-skip-next"
                class="controlIcon"
              ></v-mdi
            ></span>
            <span id="volumeControls">
              <span id="volumeButton" class="controlButton"
                ><v-mdi
                  @click="muteVolume"
                  id="volumeIcon"
                  :name="icons.volume"
                  class="controlIcon"
                ></v-mdi
              ></span>
              <div
                id="volumeSlider"
                ref="volume"
                @mousedown="changeVolumeMouseDown"
                @mousemove="changeVolumeMouseMove"
                @mouseup="changeVolumeMouseUp"
                @click="changeVolume"
              >
                <div
                  id="volumeSliderInside"
                  v-bind:style="{ width: volume.width }"
                >
                  <div id="volumeSliderInsideValue"></div>
                </div>
              </div>
            </span>
          </div>
          <div id="rightButtons">
            <span
              id="fullScreenButton"
              class="controlButton"
              @click="toggleFullScreen"
              ><v-mdi
                id="fullScreenIcon"
                :name="icons.fullScreen"
                class="controlIcon"
              ></v-mdi
            ></span>
            <span
              id="settingsButton"
              @click="showSettings"
              class="controlButton"
              ><v-mdi
                id="settingsIcon"
                name="mdi-cog"
                class="controlIcon"
              ></v-mdi
            ></span>
            <div id="settingsWindow" v-show="settingsWindowOpened">
              <div id="qualityName">
                <v-mdi name="mdi-cog" class="controlIcon" /><span>Quality</span>
              </div>
              <div
                class="quality"
                v-for="(quality, index) in qualities"
                :key="quality"
                @click="
                  () => {
                    this.selectQuality(index);
                  }
                "
              >
                <v-mdi
                  v-if="index === selectedQualityIndex"
                  name="mdi-check"
                  class="controlIcon"
                ></v-mdi
                ><span>{{ quality.qualityName }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          id="timeBar"
          @click="seekVideo"
          @mousedown="seekVideoMouseDown"
          @mousemove="seekVideoMouseMove"
          @mouseup="seekVideoMouseUp"
          ref="timeBar"
        >
          <div id="timeBarInside" v-bind:style="{ width: timeBar.width }"></div>
        </div>
      </div>

      <div
        class="smallOverlay"
        @click="
          () => {
            this.selectVideo(index);
          }
        "
        v-for="(overlay, index) in overlays"
        v-bind:style="{
          borderWidth: overlay.id === selectedVideoIndex ? '1px' : '0px',
          width: overlay.width,
          height: overlay.height,
          left: overlay.x,
          top: overlay.y,
        }"
      ></div>
    </div>
  </div>
</template>

<script>
import VMdi from "vue-mdijs/src/components/vue-mdijs.vue";
import {
  mdiPlay,
  mdiPause,
  mdiSkipNext,
  mdiVolumeHigh,
  mdiVolumeLow,
  mdiVolumeMedium,
  mdiVolumeOff,
  mdiFullscreen,
  mdiFullscreenExit,
  mdiCog,
  mdiCheck,
  mdiSync,
  mdiSyncOff,
} from "@mdi/js";
VMdi.add({
  mdiPlay,
  mdiPause,
  mdiSkipNext,
  mdiVolumeHigh,
  mdiVolumeLow,
  mdiVolumeMedium,
  mdiVolumeOff,
  mdiFullscreen,
  mdiFullscreenExit,
  mdiCog,
  mdiCheck,
  mdiSync,
  mdiSyncOff,
});
import VideosSynchronizer from "./lib/VideosSynchronizer";
import MultiViewComposer from "./lib/MultiViewComposer";

export default {
  components: {
    VMdi,
  },
  props: {
    url: {
      type: String,
      default: "",
    },
  },
  computed: {
    videos: function (
      allVideos,
      selectedSourceIndex,
      selectedQualityIndex,
      selectedVideoIndex
    ) {
      if (this.allVideos) {
        var videos =
          this.allVideos.videos[this.selectedSourceIndex].data[
            this.selectedQualityIndex
          ].videos;
        return videos.filter((video, index) => {
          return index !== this.selectedVideoIndex;
        });
      }
    },
    mainVideo: function (
      allVideos,
      selectedSourceIndex,
      selectedQualityIndex,
      selectedVideoIndex
    ) {
      if (this.allVideos) {
        var videos =
          this.allVideos.videos[this.selectedSourceIndex].data[
            this.selectedQualityIndex
          ].videos;
        return videos[this.selectedVideoIndex];
      }
    },
    qualities: function (allVideos, selectedSourceIndex) {
      if (this.allVideos) {
        return this.allVideos.videos[this.selectedSourceIndex].data;
      }
    },
  },
  data() {
    return {
      allVideos: null,
      videoCurrentTime: 0,
      settingsWindowOpened: false,
      resizeObserver: null,
      selectedSourceIndex: 0,
      selectedQualityIndex: 0,
      selectedVideoIndex: 0,
      showNextButton: false,
      showAutopilotButton: false,
      backgroundImagePoster: null,
      playing: false,
      runner: null,
      icons: {
        play: "mdi-play",
        fullScreen: "mdi-fullscreen",
        volume: "mdi-volume-high",
        autopilot: "mdi-sync",
      },
      mainOverlay: {
        width: "100%",
        height: "100%",
      },
      timeBar: {
        width: "0%",
        mouseDown: false,
      },
      volume: {
        width: "100%",
        mouseDown: false,
        mutedVolume: 1,
      },
      autopilot: {
        active: true,
        data: [],
      },
      overlays: [],
    };
  },
  created() {
    this.overlays.push({ id: 0, width: 0, height: 0, x: 0, y: 0 });
    this.overlays.push({ id: 1, width: 0, height: 0, x: 0, y: 0 });
    this.overlays.push({ id: 2, width: 0, height: 0, x: 0, y: 0 });
    this.overlays.push({ id: 3, width: 0, height: 0, x: 0, y: 0 });
    this.copyVideo = this.copyVideo.bind(this);
    this.reloadVideos = this.reloadVideos.bind(this);
    try {
      if (this.url && this.url !== "") {
        fetch(this.url)
          .then((response) => response.json())
          .then((json) => {
            this.allVideos = json;
            if(this.allVideos.videos.length > 1) {
              this.showNextButton = true;
              this.showAutopilotButton = true;
            }
            document.title = 
              "Spltchr - " +
              this.allVideos.videos[this.selectedSourceIndex].title;
              if(this.allVideos.poster){
                this.backgroundImagePoster =  this.allVideos.poster;
              }
            this.videosSynchronizer = new VideosSynchronizer();
            this.reloadVideos();
            this.videosSynchronizer.addEventListener("buffering", (e) =>
              this.onBuffering(e.detail.videoId)
            );
            this.videosSynchronizer.addEventListener("play", (e) =>
              this.onPlay()
            );
            this.videosSynchronizer.addEventListener("pause", (e) =>
              this.onPause()
            );
            this.videosSynchronizer.addEventListener("timeupdate", (e) =>
              this.onTimeupdate(e.detail)
            );
            this.videosSynchronizer.addEventListener("volumechange", (e) =>
              this.onVolumechange(e.detail)
            );
          });
      }
    } catch (e) {
      console.log("Cannot get videos");
    }
  },
  mounted() {
    this.canvas = this.$refs.canvas;
    this.videosComposer = new MultiViewComposer();
    this.offscreenCanvas = this.videosComposer.getCanvas();
    this.context = canvas.getContext("2d", { alpha: false });
    this.reloadCanvas();
    this.resizeObserver = new ResizeObserver(this.reloadCanvas);
    this.resizeObserver.observe(this.$refs.container);
  },
  unmounted() {
    this.resizeObserver.unobserve(this.$refs.container);
    this.resizeObserver.disconnect();
  },
  methods: {
    seekVideo(e) {
      var offset = e.offsetX;
      var total = this.$.refs.timeBar.getBoundingClientRect().width;
      this.videosSynchronizer.seekToRatio(
        Math.max(Math.min(offset / total, 1), 0)
      );
    },
    seekVideoMouseDown(e) {
      this.timeBar.mouseDown = true;
      this.seekVideo(e);
    },
    seekVideoMouseUp(e) {
      this.timeBar.mouseDown = false;
      this.seekVideo(e);
    },
    seekVideoMouseMove(e) {
      if (this.timeBar.mouseDown) {
        var offset = e.offsetX;
        var total = this.$.refs.timeBar.getBoundingClientRect().width;
        this.seekVideo(e);
        this.timeBar.width = (offset / total) * 100 + "%";
      }
    },
    onTimeupdate(e) {
      this.timeBar.width = (e.currentTime / e.totalDuration) * 100 + "%";
      if (this.autopilot.active) {
        var videoIndex = 0;
        this.autopilot.data.forEach((autopilotEvent) => {
          if (autopilotEvent.timestamp < e.currentTime) {
            videoIndex = autopilotEvent.videoIndex;
          }
        });
        console.log("changing");
        if (videoIndex !== this.selectedVideoIndex) {
          this.selectedVideoIndex = videoIndex;
          this.videosComposer.switchTo(this.selectedVideoIndex);
        }
      }
    },
    changeVolume(e) {
      var offset = e.offsetX;
      var total = this.$.refs.volume.getBoundingClientRect().width;
      this.videosSynchronizer.changeVolume(
        Math.max(Math.min(offset / total, 1), 0)
      );
    },
    changeVolumeMouseDown(e) {
      this.volume.mouseDown = true;
    },
    changeVolumeMouseUp(e) {
      this.volume.mouseDown = false;
    },
    changeVolumeMouseMove(e) {
      if (this.volume.mouseDown) {
        this.changeVolume(e);
      }
    },
    muteVolume() {
      if (this.volume.width === "0%") {
        this.videosSynchronizer.changeVolume(this.volume.mutedVolume);
      } else {
        this.videosSynchronizer.changeVolume(0);
      }
    },
    onVolumechange(e) {
      this.volume.width = e * 100 + "%";
      if (e > 0.5) {
        this.icons.volume = "mdi-volume-high";
      } else {
        if (e > 0) {
          this.icons.volume = "mdi-volume-medium";
        } else {
          this.icons.volume = "mdi-volume-off";
        }
      }
      if (e !== 0) {
        this.volume.mutedVolume = e;
      }
    },
    reloadVideos() {
      this.videosSynchronizer.flushVideos();
      var videoServer = this.allVideos.location;
      this.allVideos.videos[this.selectedSourceIndex].data[
        this.selectedQualityIndex
      ].videos.forEach((video) => {
        this.videosSynchronizer.addVideo(videoServer + video);
      });
      this.principalVideo = this.videosSynchronizer.getMainVideo();
      if (this.playing) {
        this.videosSynchronizer.play();
        requestAnimationFrame(this.copyVideo);
      }
      this.loadAutopilot();
    },
    onPlay() {
      this.icons.play = "mdi-pause";
      this.backgroundImagePoster = null
      this.playing = true;
    },
    onPause() {
      this.icons.play = "mdi-play";
      this.playing = false;
    },
    onBuffering(videoId) {
      console.log("Player is buffering, waiting for video #" + videoId);
    },
    reloadCanvas() {
      this.canvas.width = this.canvas.getBoundingClientRect().width;
      this.canvas.height = this.canvas.getBoundingClientRect().height;
      this.videosComposer.resize(canvas.width, canvas.height);
    },
    selectVideo(index) {
      this.selectedVideoIndex = index;
      this.videosComposer.switchTo(this.selectedVideoIndex);
      this.autopilotChange(false);
    },
    play() {
      if (this.playing) {
        this.videosSynchronizer.pause();
      } else {
        this.videosSynchronizer.play();
        requestAnimationFrame(this.copyVideo);
      }
    },
    autopilotChange(value) {
      if (typeof value !== "undefined") {
        this.autopilot.active = value;
      } else {
        this.autopilot.active = !this.autopilot.active;
      }
      this.icons.autopilot = this.autopilot.active
        ? "mdi-sync"
        : "mdi-sync-off";
    },
    loadAutopilot() {
      this.autopilot.data =
        this.allVideos.videos[this.selectedSourceIndex].autopilot;
    },
    nextVideo() {
      console.log(this.allVideos.videos.length);
      this.selectedSourceIndex =
        (this.selectedSourceIndex + 1) % this.allVideos.videos.length;
      this.selectedQualityIndex = 0;
      document.title =
        "Spltchr - " + this.allVideos.videos[this.selectedSourceIndex].title;
      this.playing = false;
      this.backgroundImagePoster = this.allVideos.poster;
      this.videosSynchronizer.pause();
      this.reloadVideos();
      this.videosSynchronizer.seekTo(0);
      this.timeBar.width = "0%";
      this.icons.play = "mdi-play";
      this.playing = false;
    },
    showSettings() {
      this.settingsWindowOpened = !this.settingsWindowOpened;
    },
    selectQuality(index) {
      this.selectedQualityIndex = index;
      this.settingsWindowOpened = false;
      this.reloadVideos();
    },
    toggleFullScreen() {
      if (!document.fullscreenElement) {
        if (navigator.userAgent.includes("Safari")) {
          document.documentElement.webkitRequestFullScreen();
        } else {
          document.documentElement.requestFullscreen();
        }
        this.icons.fullScreen = "mdi-fullscreen-exit";
        this.reloadCanvas();
      } else {
        if (document.exitFullscreen) {
          if (navigator.userAgent.includes("Safari")) {
            document.webkitCancelFullScreen();
          } else {
            document.exitFullscreen();
          }
          this.icons.fullScreen = "mdi-fullscreen";
          setTimeout(() => {
            this.reloadCanvas();
          }, 100);
        }
      }
    },

    copyVideo() {
      var isLandscape =
        this.canvas.getBoundingClientRect().width /
          this.canvas.getBoundingClientRect().height >
        1;
      if (isLandscape) {
        var mainWindowWith =
          this.canvas.width -
          ((this.canvas.height / 4) * this.principalVideo.videoWidth) /
            this.principalVideo.videoHeight;
        var canvasPosition = {
          x: 0,
          y: 0,
          width: mainWindowWith,
          height: this.canvas.height,
        };
        this.mainOverlay.width = canvasPosition.width + "px";
        this.mainOverlay.height = canvasPosition.height + "px";
        this.videosSynchronizer.tracks.forEach((video, id) => {
          this.videosComposer.drawFrame(video, id, isLandscape);
          var smallCanvasPosition = {
            x: mainWindowWith,
            y: (this.canvas.height / 4) * id,
            width: this.canvas.width - mainWindowWith,
            height: this.canvas.height / 4,
          };
          this.drawOverlay(smallCanvasPosition, id);
        });
      } else {
        var mainWindowHeight =
          this.canvas.height -
          ((this.canvas.width / 4) * this.principalVideo.videoHeight) /
            this.principalVideo.videoWidth;
        var canvasPosition = {
          x: 0,
          y: 0,
          width: this.canvas.width,
          height: mainWindowHeight,
        };
        this.mainOverlay.width = canvasPosition.width + "px";
        this.mainOverlay.height = canvasPosition.height + "px";
        this.videosSynchronizer.tracks.forEach((video, id) => {
          this.videosComposer.drawFrame(video, id, isLandscape);
          var smallCanvasPosition = {
            x: (this.canvas.width / 4) * id,
            y: mainWindowHeight,
            width: this.canvas.width / 4,
            height: this.canvas.height - mainWindowHeight,
          };
          this.drawOverlay(smallCanvasPosition, id);
        });
      }
      this.context.drawImage(
        this.offscreenCanvas,
        0,
        0,
        this.canvas.width,
        this.canvas.height
      );
      requestAnimationFrame(this.copyVideo);
    },
    drawOverlay(position, id) {
      this.overlays.forEach((overlay) => {
        if (overlay.id === id) {
          overlay.width = position.width + "px";
          overlay.height = position.height + "px";
          overlay.x = position.x + "px";
          overlay.y = position.y + "px";
        }
      });
    },
  },
};
</script>

<style>
#playerContainer {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  overflow: hidden;
  transition: width 0.1s, height 0.1s;
}
.fullscreen {
  display: flex;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.video {
  /*width: 30%;
  height: 30%;*/
  width: 100%;
  background: black;
}
#smallerVideosContainer {
  height: 100%;

  flex-direction: column;
  display: flex;
}
#canvas {
  position: absolute;
  width: 100%;
  height: 100%;
}
#overlays {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  cursor: pointer;
}
#mainOverlay {
  position: absolute;

  height: 100%;
  left: 0;
  top: 0;
}
.smallOverlay {
  position: absolute;
  border: 0px solid yellow;
  box-sizing: border-box;
}

#mainOverlayClickZone {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#playBar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
  height: 48px;
  background-repeat: repeat-x;
  -webkit-transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
  display: flex;
  opacity: 1;
}
#timeBar {
  width: 100%;
  position: absolute;
  height: 3px;
  opacity: 1;
  background: lightgrey;
  bottom: 48px;
}
.controlIcon {
  width: 48px;
  text-align: center;
  font-size: 30px;
}
#leftButtons {
  width: 70%;
  display: flex;
}
#rightButtons {
  width: 30%;
  display: flex;
  flex-flow: row-reverse;
}
.controlButton {
  cursor: pointer;
  width: 48px;
  text-align: center;
  height: 48px;
  color: white;
  display: flex;
  align-items: center;
}
#volumeIcon {
  font-size: 24px;
}
#settingsIcon {
  font-size: 24px;
}

#timeBarInside {
  background: red;
  position: absolute;
  left: 0;
  height: 100%;
  width: 0%;
}

#timeBar:hover {
  height: 5px;
}
#timeBar:hover #timeBarInside::after {
  width: 10px;
  height: 10px;
  display: block;
  right: -5px;
  top: -3px;
  position: absolute;
  content: " ";
  background: red;
  border-radius: 100%;
}
.timeBarInsideSelected::after {
  width: 10px;
  height: 10px;
  display: block;
  right: -5px;
  top: -3px;
  position: absolute;
  content: " ";
  background: red;
  border-radius: 100%;
}

#volumeControls {
  display: flex;
  align-items: center;
}
#volumeSlider {
  height: 100%;
  width: 52px;
  display: flex;
  flex: 1;
  align-items: center;
}
#volumeSliderInside {
  width: 100%;
  height: 3px;
  background: rgba(211, 211, 211, 0.25);
}

#volumeSliderInsideValue {
  width: 100%;
  height: 100%;
  position: relative;
  background: white;
}
#volumeSliderInsideValue::after {
  width: 10px;
  height: 10px;
  display: block;
  right: -5px;
  top: -3px;
  position: absolute;
  content: " ";
  background: white;
  border-radius: 100%;
}

#settingsWindow {
  width: 150px;
  bottom: 63px;
  right: 10px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(28, 28, 28, 0.9);
}
#qualityName {
  height: 50px;
  padding: 3px;
  color: white;
  box-sizing: border-box;
  border-bottom: 1px solid white;
  width: 100%;
  align-items: center;
  font-size: 12pt;
  padding-left: 5px;
  display: flex;
}
.quality {
  height: 50px;
  padding: 3px;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  display: flex;
  font-size: 11pt;
  color: white;
  font-family: Roboto;
  align-items: center;
}
.quality:hover {
  background: rgba(211, 211, 211, 0.25);
}
.qualityIcon {
  width: 30px;
  text-align: center;
  font-size: 20px;
}

* {
  box-sizing: border-box;
  font-family: sans-serif;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
